html,
body {
  max-width: 100vw;
  width: 100vw;
  overflow-x: hidden;
}

body {
  font-family: Arial, sans-serif;
  background-color: #ffffff;
  color: #908e9b;
  margin: 0;
  padding: 0;
}

.bg-pink {
  background-color: #ffdfdf;
}

.satisfy-regular {
  font-family: "Satisfy", cursive;
  font-weight: 400;
  font-style: normal;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.intro-1 {
  animation: fadeInUp 1s ease forwards;
  animation-delay: 500ms;
  opacity: 0;
}

.intro-2 {
  animation: fadeInUp 1s ease forwards;
  animation-delay: 1500ms;
  opacity: 0;
}

.intro-text-1 {
  animation: fadeIn 1s ease forwards;
  animation-delay: 2500ms;
  opacity: 0;
}
.intro-text-2 {
  animation: fadeIn 1s ease forwards;
  animation-delay: 3500ms;
  opacity: 0;
}

.intro-text-4 {
  animation: fadeIn 1s ease forwards;
  animation-delay: 4000ms;
  opacity: 0;
}

.btn {
  background-color: #6051a7;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #6051a7;
  font-family: "Satisfy", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 36px;
}

/* 
header,
section,
footer {
  padding: 30px;
  text-align: center;
}

h1,
h2 {
  color: #6051a7;
  font-family: "Satisfy", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 36px;
}

p,
li {
  color: #6051a7;
}

ul {
  list-style-type: none;
  padding: 0;
}

li::before {
  content: "• ";
  color: #ffdfdf; /* Secondary color for bullet points */
/* } */
/* a {
  color: #6051a7;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

footer {
  margin-top: auto;
  background-color: #ffdfdf;
  color: #6051a7;
  width: 100%;
  text-align: center;
  padding: 10px;
}  */
